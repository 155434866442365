import React from 'react'
import { connect } from 'react-redux'
import { Box, Image, Button, TextInput, Heading, CheckBox, Menu } from 'grommet'
import AppLayout from '../components/app-layout'

import {
  addDataEntry, getDataEntry,
} from '../actions/data-entry'
const mapStateToProps = state => ({
  ...state.user,
  isAdmin: state.admin.isAdmin,
  enteredData: state.dataEntry
})

const mapDispatchToProps = dispatch => ({
  onAddDataEntry: data => dispatch(addDataEntry(data)),
  onGetDataEntry: id => dispatch(getDataEntry(id)),
})

class DataEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      foodResults: '',
      diet: {},
      allergies: {},
      glycemicIndex: '',
      iodine: '',
      choline: '',
      probiotic: '',
      instant: {},
      open: true,
      tags: '',
      foodGroup: {},
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.foodResults !== this.state.foodResults && this.state.foodResults !== '') {
      this.props.onGetDataEntry(
        this.state.foodResults.foods[0].nix_item_id
        || this.state.foodResults.foods[0].ndb_no
      )
    }
    if (prevProps.enteredData !== this.props.enteredData) {
      const data = this.props.enteredData.dataEntry
      this.setState({
        diet: data.diets,
        allergies: data.allergies,
        glycemicIndex: data.glycemic,
        iodine: data.nutrition.iodine,
        choline: data.nutrition.choline,
        probiotic: data.nutrition.probiotic,
        foodGroup: data.foodGroup,
        tags: data.tags ? `${data.tags.map(tag => tag)}` : '',
      })
    }
  }

  queryResults = async val => {
    try {
      const results = await fetch('https://trackapi.nutritionix.com/v2/natural/nutrients', {
        method: 'POST',
        headers: {
          Accept: 'application/join',
          'Content-Type': 'application/json',
          'x-app-id': 'f0e2750b',
          'x-app-key': '3d839499bcd20afdf286dd48b3bcea55',
          'x-remote-user-id': this.props.uid,
        },
        body: JSON.stringify({
          query: val,
          locale: 'en_US',
        }),
      })
      this.setState({ foodResults: await results.json(), open: false, instant: {} })
    } catch (err) {
      console.error(err)
    }
  }

  queryResults1 = async val => {
    try {
      const results = await fetch(`https://trackapi.nutritionix.com/v2/search/instant?query=${this.state.search}`, {
        method: 'GET',
        headers: {
          Accept: 'application/join',
          'Content-Type': 'application/json',
          'x-app-id': 'f0e2750b',
          'x-app-key': '3d839499bcd20afdf286dd48b3bcea55',
          'x-remote-user-id': this.props.uid,
        },
      })
      let result = await results.json()
      if (result.common) {
        result.common = result.common.map(item => ({
          ...item,
          label: item.food_name,
          onClick: () => this.queryResults(item.food_name)
        }))
      }
      if (result.branded) {
        result.branded = result.branded.map(item => ({
          ...item,
          label: item.food_name,
          onCLick: () => this.queryResults(item.food_name)
        }))
      }
      this.setState({ instant: result, open: true })
    } catch (err) {
      console.error(err)
    }
  }

  checkList = (items, type) => {
    return items.map(item =>
      <CheckBox
        key={item}
        checked={!this.state[type] || !this.state[type][item] ? false : true}
        label={item}
        onChange={(event) => this.setState({
          [type]: { ...this.state[type], ...{ [item]: event.target.checked } }
        })}
      />
    )
  }

  submitData = () => {
    const food = this.state.foodResults.foods[0]
    const submitData = {
      nutritionixId: food.nix_item_id || food.ndb_no,
      name: food.food_name,
      diets: this.state.diet,
      allergies: this.state.allergies,
      glycemic: Number(this.state.glycemicIndex),
      nutrition: {
        iodine: Number(this.state.iodine),
        choline: Number(this.state.choline),
        probiotic: Number(this.state.probiotic),
      },
      tags: this.state.tags,
      foodGroup: this.state.foodGroup,
    }

    this.props.onAddDataEntry(submitData)
    this.setState({
      search: '',
      foodResults: '',
      diet: {},
      allergies: {},
      glycemicIndex: '',
      iodine: '',
      choline: '',
      probiotic: '',
      open: true,
      instant: {},
      tags: [],
      foodGroup: {},
    })
  }

  foodItem = () => {
    const food = this.state.foodResults.foods[0]
    const foodGroup = [
      'desserts',
      'salty Snacks',
      'sweet Snacks',
      'baked Goods',
      'drinks',
      'grains',
      'protein',
      'cooking oils',
      'sauces & dressings',
      'fast food',
      'dairy',
      'meats',
    ]
    const dietsList = [
      'ketogenic',
      'paleo',
      'atkins',
      'mediterranean',
      'pescatarian',
      'vegan',
      'vegetarian',
    ]
    const allergiesList = [
      'gluten',
      'peanut',
      'sesame',
      'tree_nut',
      'fish',
      'crustacean',
      'shellfish',
      'lactose',
      'egg',
      'soy',
      'meat',
      'dairy',
    ]

    return (
      <Box style={{ flexDirection: 'column', marginVertical: 16 }}>
        <Image style={{ height: 50, width: 50 }} fit={'contain'} src={food.photo.thumb} />
        <p>Name: {food.food_name}</p>
        <p>Id: {food.nix_item_id || food.ndb_no}</p>
        <p>Tags</p>
        <TextInput
          placeholder="vegan,egg free,lactose,gluten,nut free,etc"
          value={this.state.tags}
          onChange={event =>
            this.setState({
              tags: event.target.value
            })
          } />
        <p>Food Groups</p>
        {this.checkList(foodGroup, 'foodGroup')}
        <p>Diets</p>
        {this.checkList(dietsList, 'diet')}
        <p>Allergies</p>
        {this.checkList(allergiesList, 'allergies')}
        <p>Glycemic</p>
        <TextInput
          placeholder="glycemic index per 1 gram"
          value={this.state.glycemicIndex}
          onChange={event =>
            this.setState({
              glycemicIndex: event.target.value
            })
          } />
        <p>Nutrition</p>
        <TextInput
          placeholder="iodine per 1 gram"
          value={this.state.iodine}
          onChange={event =>
            this.setState({
              iodine: event.target.value
            })
          } />
        <TextInput
          placeholder="choline per 1 gram"
          value={this.state.choline}
          onChange={event =>
            this.setState({
              choline: event.target.value
            })
          } />
        <TextInput
          placeholder="probiotic per 1 gram"
          value={this.state.probiotic}
          onChange={event =>
            this.setState({
              probiotic: event.target.value
            })
          } />
      </Box>
    )
  }

  render() {
    const { uid, isAdmin } = this.props
    if (isAdmin) {
      return (
        <AppLayout>
          <Box
            pad={{ horizontal: 'large', vertical: 'large' }}
            margin={{ horizontal: 'small' }}
          >
            <Heading level={2} color="accent-2">
              Data Entry
            </Heading>
            <Box style={{ flexDirection: 'row' }}>
              <TextInput
                placeholder="search food"
                value={this.state.search}
                onChange={event =>
                  this.setState({ search: event.target.value })
                }
              />
              <Button
                label="Search"
                color="accent-1"
                primary
                style={{ marginLeft: 16 }}
                onClick={() => this.queryResults1()}
              />
            </Box>
            {
              Object.keys(this.state.instant).length > 0 ?
                (<div>
                  <Menu
                    open={this.state.open}
                    label="foods"
                    items={this.state.instant.common.concat(this.state.instant.common)}
                  />
                </div>)
                : null
            }
            {this.state.foodResults.foods ?
              this.foodItem()
              : null
            }
            <Button
              label="Submit"
              color="accent-1"
              primary
              style={{ marginLeft: 16 }}
              onClick={() => this.submitData()}
            />
          </Box>
        </AppLayout>
      )
    }
    return (
      <AppLayout>
        <Box
          pad={{ horizontal: 'large', vertical: 'large' }}
          margin={{ horizontal: 'small' }}
        >
          <Heading level={2} color="accent-2">
            404 Error
          </Heading>
        </Box>
      </AppLayout>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataEntry)
